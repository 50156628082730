<template>
  <list-template
    :search-config="searchConfig"
    @onSearch="onSearch"
    :table-config="tableConfig"
    :table-data="tableData"
    :total="total"
    :loading="loading"
    :current-page="page"
    :isDownload="true"
    @onAdd="$router.push('./add')"
    @onExportData="handleDownLoad"
    @onChangePage="handleCurrentChange"
    @onHandle="handleClick"
    @onChange="onChange" />
</template>

<script>
import { tableListMixin } from '@/utils/mixins'
import { changeOption } from "@/utils"
import { zsGet, fileDownload } from "@/api"
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  mixins: [tableListMixin],
  created() {
    this.getConfig()
  },
  methods: {
    getConfig() {
      zsGet('/finance/fee-plan/get-check').then(res => {
        const { school = [] } = res
        this.searchConfig[1].options = changeOption({ list: school, label: 'school_name', value: 'id' })
      })
    },
    getData() {
      this.getDataApi('/zhaosheng/api/finance/fee-stu/order-list')
    },
    onBeforeSearch(val){
      const { time, ...data } = val
      if(time){
        data.start_at = time[0]
        data.end_at = time[1]
      }
      this.search = data
    },
    handleClick(row) {
      this.$router.push(`./detail?id=${row.id}`)
    },
    handleDownLoad() {
      fileDownload('/zhaosheng/api/finance/fee-stu/export-order-list', {...this.search}, { name: '后勤收费订单', show: true })
    }
  },
  data() {
    return {
      code: '',
      visible: false,
      searchConfig: [
        { tag: 'select', placeholder: '筛选支付状态', prop: 'pay_status', options: [{ label: '待支付', value: 0 },{ label: '已支付', value: 1 },{ label: '已取消', value: 2 }] },
        { tag: 'select', placeholder: '筛选入账校区', prop: 'income_to_school', options: [] },
        { tag: 'daterange', prop: 'time', start: "筛选支付时间开始范围", end: "筛选支付时间结束范围", rangeText: '-' },
        { tag: 'input', placeholder: '搜索学生姓名', prop: 'student_name' },
      ],
      tableConfig: [
        { prop: 'pay_status_text', label: '支付状态' },
        { prop: 'student_name', label: '学生姓名' },
        { prop: 'fee_type', label: '生活费类型' },
        { prop: 'school_name', label: '入账校区' },
        { prop: 'money_actual', label: '支付金额' },
        { prop: 'pay_time', label: '支付成功时间' },
        { label: '操作', width: '130rem', handle: true, fixed: 'right' }
      ]
    }
  },
}
</script>

<style lang="scss" scoped></style>
